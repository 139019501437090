<template>
  <div class="about">
    <Head :hId="3"></Head>
    <div class="main">
      <div class="content">
        <div class="bg">
          <div class="title">
            <img src="@/assets/img/Index_JobSeeker/about.png" alt="" />
            <p><span>关于我们</span></p>
          </div>
          <div class="introduce"  v-html="unEscapeHTML(about.content)">
<!--            {{ about.content }}-->
          </div>
          <ul>
            <li>软件版本<span></span><span>{{versionNum[0].content}}</span></li>
            <li>
              官方网站<span></span
              ><a target="_blank" href="http://www.danlingzp.com">{{versionNum[1].content}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Head from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { protocol,version } from "@/api/login";
export default {
  name: "about",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      about: {},
      versionNum:{}
    };
  },
  mounted() {
    this.getAbout();
  },
  methods: {
    getAbout() {
      protocol(4).then((res) => {
        if (res.data) {
          this.about = res.data;
        }
      });
      version({}).then(res=>{
        this.versionNum=res.data
      })
    },
      unEscapeHTML(html) {
          html= "" +html;
          return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'");
      },

  },
};
</script>
<style lang="scss">
.about {
  width: 100%;
  height: 100%;
  .main {
    min-height: calc(100% - 278px);
  }
  .content {
    .bg {
      margin-top: 20px;
      min-height: 582px;
      background: url("../../assets/img/Index_JobSeeker/bg.png") no-repeat;
      background-size: 100% 100%;
      margin-bottom: 200px;
      padding: 24px 16px;
      .title {
        position: relative;
        p {
          position: absolute;
          bottom: -11px;
          left: 40px;
          font-size: 34px;
          font-weight: bold;
          color: #333333;
          padding-bottom: 30px;
          border-bottom: 1px solid #eeeeee;
          width: 544px;
          span {
            border-bottom: 2px solid #3580df;
            padding-bottom: 30px;
          }
        }
      }
      .introduce {
        width: 544px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 28px;
        margin-top: 49px;
      }
      ul {
        margin-top: 19px;
        li {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          margin-bottom: 41px;
          display: flex;
          align-items: center;
          &:last-child {
            margin-bottom: 41px;
          }
          span {
            &:nth-child(1) {
              display: inline-block;
              width: 192px;
              border-bottom: 1px dotted #cccccc;
              margin: 0 11px;
            }
          }
          a {
            border-bottom: 1px solid #0780e6;
            color: #0780e6;
          }
        }
      }
    }
  }
}
</style>
