import axios from '@/utils/http'

// 图片验证码
export const captcha = function () {
  return axios.get('/power/captcha')
}
// 登录
export const login = function (data) {
  return axios.post('/power/login', data)
}
// 注册
export const register = function (data) {
  return axios.post('/power/cms/login/add', data)
}
// 短信验证码
export const shortCaptcha = function (data) {
  return axios.get('/power/short/captcha', {params: data})
}
// 退出登录
export const logout = function () {
  return axios.get('/power/logout')
}
// 忘记密码
export const rest = function (data) {
  return axios.put('/power/cms/login/rest', data)
}
// 协议查看
export const protocol = function (type) {
  return axios.get('/power/cms/protocol/detail/'+ type)
}
// 版本-网址
export const version = function (data) {
  return axios.get('/power/cms/platform/information/version',data)
}
